import React from 'react'
import { Link } from 'gatsby'
import { useStyletron } from 'baseui'
import { Table } from 'baseui/table'
import { H2, Paragraph3, Paragraph2 } from 'baseui/typography'
import { FcCheckmark } from 'react-icons/fc'
import { BsDash } from 'react-icons/bs'
import Emoji from './Emoji'
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from 'baseui/popover'
import { isMobile } from 'react-device-detect'

const Pricing = () => {
  const [css] = useStyletron()

  const pricingContainer = css({
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    marginTop: '2rem',
    marginBottom: '1rem',
  })

  const pricingTitle = css({
    marginBottom: '2rem',
    textAlign: 'center',
  })

  const tableHeader = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
  })

  return (
    <div className={pricingContainer}>
      <div className={pricingTitle} id="pricing">
        <H2>Compara nuestros servicios:</H2>
        {/* <Paragraph3>
          We want your business to grow with us. Start for free, then be a Pro
        </Paragraph3> */}
      </div>
      <div className="pricingTable">
        <Table
          columns={[
            <div className={tableHeader}>
              Funciones incluidas{' '}
              <small>
                {isMobile
                  ? '(toca para más info)'
                  : '(pasa el cursor para más info)'}
              </small>
            </div>,
            <PlanHeader
              title="Walink Gratis"
              description="USD $0 Siempre"
              to="/#crear-link"
            />,
            <PlanHeader
              title="Walink Premium"
              description="USD $6/año por link"
              emoji="👑"
              to="/premium"
            />,
          ]}
          data={[
            [
              <PlanItem content="Aumenta la conversión de clics a chats, con dominio wa.link los usuarios saben que abrirán un chat de WhatsApp.">
                Clic para chatear con dominio wa.link
              </PlanItem>,
              <Check />,
              <Check />,
            ],
            [
              <PlanItem content="Un mensaje personalizado que los usuarios podrán enviarte para iniciar la conversación, sin codificaciones complejas y sin afectar la longitud del link.">
                Mensaje personalizado
              </PlanItem>,
              <Check />,
              <Check />,
            ],
            [
              <PlanItem content="Código QR fácil de escanear que abrirá un chat de WhatsApp, como si fuese un link.">
                Código QR
              </PlanItem>,
              <Check />,
              <Check />,
            ],
            [
              <PlanItem content="Los links personalizados son una forma profesional de compartir tu WhatsApp en cualquier canal digital y medios tradicionales como TV, radio, imágenes o videos. Los usuarios podrán llegar a tu chat con solo ingresar tu link personalizado en su navegador, sin necesidad de recordar números de teléfono.">
                Link personalizado (wa.link/TuMarca)
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Edita la información de tu link en cualquier momento. Actualiza el mensaje personalizado y número de teléfono sin preocuparte por perder clientes o modifica la URL si lo necesitas.">
                Actualiza la info del link (teléfono, URL y mensaje
                personalizado)
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Mide la cantidad de clics en tus links por día, hora y mes. También obtienes información sobre la fuente, sistema operativo y el país de tus clientes.">
                Analítica de clics
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Haz que encuentren tu WhatsApp fácilmente indexando tu negocio en nuestro buscador de links de WhatsApp (open.wa.link/search), usado por miles de personas todos los días para abrir chats de WhatsApp sin tener que guardar números de teléfono.">
                Aparece como resultado en búsquedas
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Crea links para tu catálogo de WhatsApp Business con una URL fácil de recordar. Los usuarios podrán hacer clic y ver tus productos dispuestos para comprar.">
                Links a catálogo de WhatsApp
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="¿Tienes más de 1 línea de WhatsApp en tu negocio? Agrupa todos tus links en una página Multilink para darle opciones para chatear a tus usuarios en un solo punto de contacto: ¡Tu página Multilink!">
                Página Multilink*
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Nuestro equipo de soporte se encuentra disponible para ayudarte a resolver tus preguntas via correo electrónico">
                Soporte por correo
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Aumenta la cantidad de chats que puedes atender al distribuir de forma automática los chats entre múltiples números de WhatsApp, así podrás tener múltiples agentes detrás de ¡1 solo wa.link!">
                Multiagente (multiples líneas de WhatsApp, un solo wa.link)
              </PlanItem>,
              <Not />,
              <div style={{ textAlign: 'center', width: '100%' }}>
                <small>USD $5/agente/mes</small>
                <div>
                  <small>(14 días de prueba gratis)</small>
                </div>
              </div>,
            ],
          ]}
        />
      </div>
      <div style={{ paddingLeft: '15px' }}>
        <Paragraph3>
          <small>
            *La página Multilink está disponible para clientes con 2 o más links
            en su plan Premium
          </small>
        </Paragraph3>
      </div>
    </div>
  )
}

function Check() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <FcCheckmark size="18px" />
    </div>
  )
}

function Not() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <BsDash size="18px" style={{ color: 'darkgrey' }} />
    </div>
  )
}

function PlanHeader({ title, description, emoji, to }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'center',
      }}
    >
      <div>
        <strong>
          {emoji && <Emoji symbol={emoji} />} <Link to={to}>{title}</Link>
        </strong>
      </div>
      <div>
        <small>{description}</small>
      </div>
    </div>
  )
}

function PlanItem({ children, content }) {
  return (
    <StatefulPopover
      showArrow
      content={
        <>
          <Paragraph2 padding="scale300">
            <strong>{children}:</strong>
            <br />
            {content}
          </Paragraph2>
        </>
      }
      accessibilityType={'tooltip'}
      triggerType={TRIGGER_TYPE.hover}
      placement={PLACEMENT.left}
      overrides={{
        Body: {
          style: {
            width: '280px',
            marginRight: '-200px',
            position: 'absolute',
            textAlign: 'center',
            borderTopLeftRadius: '15px',
            borderBottomLeftRadius: '15px',
            borderTopRightRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
        Inner: {
          style: {
            borderTopLeftRadius: '15px',
            borderBottomLeftRadius: '15px',
            borderTopRightRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
      }}
    >
      {children}
    </StatefulPopover>
  )
}

export default Pricing
